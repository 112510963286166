export default [
  {
    id: 1,
    name: "Hailey P",
    date: "9/12/2018",
    location: "Phoenix",
    shortSummary: "My closet looks AMAZING",
    content:
      "My closet looks AMAZING, is great quality, and I was able to combine 2 closets into 1 (and still have some room!). I'm a VERY happy momma! Thank you for making my closet dreams come true, Classy Closets!",
  },
  {
    id: 2,
    name: "Zoe B",
    date: "7/22/2020",
    location: "Gilbert",
    shortSummary: "I could not be happier",
    content:
      "I could not be happier about the result! So much space! Everything we needed to fit did with ample space to spare!It is so amazing and the process was easy! So glad we have Classy!",
  },
  {
    id: 3,
    name: "Lorrie C",
    date: "5/19/2020",
    location: "Chandler",
    shortSummary: "Professional all the way around",
    content:
      "They should be called Classy Closets and more. We will be back when we complete the master bath and our other office! Professional all the way around. Thank you Classy!",
  },
  {
    id: 4,
    name: "Marie R",
    date: "3/12/2018",
    location: "Venice CA",
    shortSummary: "Extremely knowledgeable",
    content:
      "I've found the designers to be extremely knowledgeable and most have more than 20+ experience at Classy Closets, which says a lot about the company. They go above and beyond to satisfy their clients, and resolve all punch list items.",
  },
  {
    id: 1,
    name: "Joe D",
    date: "9/26/2017",
    location: "Carlsbad CA",
    shortSummary: "Crew was very efficient",
    content:
      "The day of the installation the crew arrived on time and was very efficient, industrious and obviously veterans who have done numerous closet installation jobs before - no rookie crews here!",
  },
  {
    id: 1,
    name: "Zoe B",
    date: "12/26/2018",
    location: "Gilbert",
    shortSummary: "Designers were super informative and helpful",
    content:
      "Their designers were super informative and helpful - true experts in their field. They had so many different options of both cabinets and countertops that allowed us to create a more functional and clean space.",
  },
  {
    id: 1,
    name: "Harold D",
    date: "9/13/2017",
    location: "Surprise, AZ",
    shortSummary: "Highly skilled professionals",
    content:
      "This was our second major closet organizational project and this company employees highly skilled professionals and their attention to detail and customization is flawless.",
  },
]
