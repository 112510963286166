import React from "react";
import PropTypes from "prop-types";
import useMobile from "../../hooks/useMobile";

import ReviewsSlider from "./ReviewsSlider";
import { Container, SliderContainer, ReviewHeading } from "./styled";

const Reviews = ({ title, reviewsData }) => {
  const isMobile = useMobile();

  console.log("isMobile:", isMobile);
  console.log("reviewsData:", reviewsData);

  return (
    <Container>
      <SliderContainer>
        <ReviewHeading>
          What Our {isMobile && <br />} Customers Say
        </ReviewHeading>
        <br />        <br />
        <ReviewsSlider reviewsData={reviewsData} />
      </SliderContainer>
    </Container>
  );
};

Reviews.propTypes = {
  title: PropTypes.string,
  reviewsData: PropTypes.array,
};

export default Reviews;

