import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Wrapper,
  CustomerName,
  ShortSummary,
  Content,
} from "./styled";

const Review = ({ name, shortSummary, content }) => {
  return (
    <Container>
      <Wrapper>
        <CustomerName>{name}</CustomerName>
        <ShortSummary>{shortSummary}</ShortSummary>
        <Content>{`"` + content + `"`}</Content>
      </Wrapper>
    </Container>
  );
};

Review.propTypes = {
  name: PropTypes.string,
  shortSummary: PropTypes.string,
  content: PropTypes.string,
};

export default Review;
