import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
`;

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacings.spacing_xl};
  border-radius: 5px;
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow2};
  background-color: ${({ theme }) => theme.colors.darkGrayOpacity4};
  min-height: 270px;

  @media only screen and (max-width: 560px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const CustomerName = styled.div`
  ${({ theme }) => theme.textStyles.titleSmall1};
`;

export const ShortSummary = styled.div`
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  color: ${({ theme }) => theme.colors.blackGreen};
  padding-bottom: 10px;
`;

export const Content = styled.div`
  ${({ theme }) => theme.textStyles.content4};
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
