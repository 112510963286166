import styled from "styled-components";

export const ReviewHeading = styled.div`
  margin: 40px 0px 30px 0px;
  padding: 0 0 0 10px;
  color: #762738;
  font-size: 2rem;
  font-weight: normal;
  font-family: Lora Bold;
  line-height: 1.5;
  text-align: center;
`;

export const Container = styled.div`
  margin-bottom:20px;
  margin-left:3.4em;
  margin-right:3.4em;
  @media only screen and (max-width: 768px) {
  margin-left:4px;
  margin-right:4px;
  }

`;

export const SliderContainer = styled.div`

  padding-left: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.desktop}px;
  padding-right: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.desktop}px;

  @media only screen and (max-width: 768px) {
    padding-left:16px;
    padding-right: ${({ theme }) =>
      theme.layout.content.paddingHorizontal.ipad}px;
  }
  @media only screen and (max-width: 560px) {
    padding-left: 38px;
    padding-right: 38px;
    margin-bottom: 26px;
  }
`;